.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  transition: opacity 0.3s ease, height 0.3s ease, visibility 0.3s ease;
}

.dropdownMenu {
  position: absolute;
  top: 50px;
  right: 25px;
  background-color: var(--white);
  width: 185px;
  height: 140px;
  border-radius: 5px;
  padding: 7px 10px;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-inline: 10px;
}
.menuItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 45px;
  width: 100%;
  cursor: pointer;
}
.menuItem img {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}

.menuItem span {
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--black);
}
