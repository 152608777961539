.rootContainer {
  width: 100%;
  margin-inline: auto;
  position: relative;
  min-height: 100vh;
  z-index: 0;
  background-color: #ededed;

  @media (min-width: 768px) {
    max-width: 400px;
  }

  @media (min-width: 1920px) {
    max-width: 600px;
  }
}

.mask {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 35vh;
  z-index: 1;
  border-radius: 0px 0px 15px 15px;
  background: var(--main-theme-color);
}
