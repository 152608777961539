.quizContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  animation-name: fade-in;
  animation-duration: 0.3s;
}

.questionContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 20px;
  padding-inline: 1px;
}

.questionContainer::-webkit-scrollbar {
  display: none;
}

.questionContainer::-webkit-scrollbar-thumb {
  display: none;
}

.button {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--main-theme-color);
  border: 0;
  margin-top: 10px;
  cursor: pointer;
}

.button span {
  color: var(--white);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.button.disabled {
  opacity: 0.4;
  cursor: unset;
}

.question {
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block: 20px;
}

.options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.optionSingle {
  display: flex;
  flex-direction: column;
}

.option {
  flex: 1;
  padding: 10px 15px;
  border-radius: 3px;
  border: 0.7px solid rgb(var(--main-bg));
  margin-bottom: 15px;
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.option:focus {
  outline: 1px solid rgba(var(--main-bg), 0.9);
}

.contentLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
}

.errorIcon {
  background-color: var(--secondary-color);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.errorIcon img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.errorMessage {
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
