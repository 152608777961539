:root {
  --primary-color-blue: #00b1cd;
  --primary: #00b1cd;
  --primary-color-red: #ba1222;
  --secondary-color: #fab515;
  --primary-blue-rgb: 0, 177, 205;
  --primary-red-rgb: 186, 18, 34;
  --secondary-rgb: 250, 181, 21;
  --white: #fff;
  --black: #1d1d1d;
  --english-font-family: "SuecaHeadlineSans", sans-serif;
  --hindi-font-family: "BHARTIYA_HINDI_100";
  --font-size: 14px;
  --font-size-hindi: 16px;
}

@font-face {
  font-family: "SuecaHeadlineSans";
  src: url("./assets/fonts/SuecaHeadSans-Regular.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "BHARTIYA_HINDI_100", sans-serif;
  src: url("./assets/fonts/bf100hin.ttf") format("truetype");
  font-display: swap;
}

body {
  height: 100%;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
