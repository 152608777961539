.accordionItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  border: 0.1px solid var(--black);
  background: var(--white);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 20px 35px 20px 10px;
  margin-block: 5px;
  position: relative;
  animation-name: fade-in;
  animation-duration: 0.3s;
}

.accordionItem.active {
  border: 1px solid var(--secondary-color);
  background: rgba(250, 181, 21, 0.05);
  box-shadow: 0px 2px 20px 0px rgba(250, 181, 21, 0.1);
}

.question {
  width: 100%;
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.question p {
  margin: 0;
}

.answer {
  max-height: 0;
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
  margin-top: 10px;
  transition: max-height 0.5s ease;
}

.answer p {
  margin: 0;
}

.answer.open {
  max-height: 1000px;
}

.iconContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  background: var(--main-theme-color);
  border-radius: 50%;
  top: 18px;
  right: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.rotateIcon {
  transition: transform 0.3s ease;
}

.rotateIcon.active {
  transform: rotate(180deg);
}

.contentLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
}

.errorIcon {
  background-color: var(--secondary-color);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.errorIcon img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.errorMessage {
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
