.termsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  animation-name: fade-in;
  animation-duration: 0.3s;
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-inline: 1px;
}

.contentContainer h3 {
  color: var(--black);
  font-family: var(--main-font);
  font-size: calc(var(--main-font-size) + 2px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.htmlContent {
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.htmlContent ol {
  padding-inline-start: 25px;
}

.contentContainer::-webkit-scrollbar {
  display: none;
}

.contentContainer::-webkit-scrollbar-thumb {
  display: none;
}

.button {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--main-theme-color);
  border: 0;
  margin-top: 10px;
  cursor: pointer;
}

.button span {
  color: var(--white);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contentLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
