.errorIcon {
  background-color: var(--secondary-color);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.errorIcon img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.errorMessage {
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
