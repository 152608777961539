.react-datepicker__input-container {
  display: flex;
}

.react-datepicker-wrapper {
  display: flex;
  flex: 1;
}

.react-datepicker-popper {
  z-index: 20;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: var(--secondary-color) !important;
  font-size: calc(var(--main-font-size) - 4px) !important;
}

.slick-dots li button:before {
  color: var(--secondary-color) !important;
}

.slick-dots li {
  margin: 0 !important;
}

.slick-prev,
.slick-next {
  display: none !important;
}
