.submitOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  transition: opacity 0.3s ease, height 0.3s ease, visibility 0.3s ease;
  animation-name: fade-in;
  animation-duration: 0.3s;
}

.bannerAnchor {
  margin-top: 15px;
  margin-bottom: 10px;
}

.bannerImg {
  width: 100%;
  height: 120px;
  object-fit: fill;
  border-radius: 10px;
}

.popupContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: var(--white);
  width: 90%;
  height: min-content;
  border-radius: 5px;
}

.innerContainer {
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.background {
  position: absolute;
  width: 100%;
  z-index: 25;
  top: 0;
  left: 0;
}

.submitContent {
  z-index: 30;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tickMark {
  width: 70px;
  height: 70px;
  object-fit: contain;
  margin-bottom: 10px;
}

.title {
  color: var(--black);
  text-align: center;
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.share {
  color: var(--black);
  text-align: center;
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.7;
  margin-bottom: 15px;
}

.socialContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  gap: 10px;
}

.socialLink {
  width: 35px;
  height: 35px;
  background-color: rgba(var(--secondary-rgb), 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.socialLink button {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialIcon img {
  width: 33px;
  height: 33px;
  object-fit: contain;
}

.title h3 {
  margin-top: 0;
  margin-bottom: 15;
}

.button {
  display: flex;
  width: 190px;
  padding: 13px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--main-theme-color);
  border: 0;
  margin-top: 20px;
  cursor: pointer;
  align-self: center;
}

.button span {
  color: var(--white);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contentLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
