.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.headLine {
  color: var(--black);
  font-family: var(--main-font);
  font-size: calc(var(--main-font-size) + 4px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin: 0;
}

.formContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-block: 40px;
}

.form {
  display: flex;
  flex-direction: column;
  padding-inline: 1px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.input {
  border-radius: 3px;
  border: 0.5px solid var(--secondary-color);
  padding: 15px 17px;
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input:focus {
  outline: 1px solid var(--secondary-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkboxInput {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  font-family: var(--main-font);
  margin: 0;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.checkboxInput:checked {
  accent-color: var(--main-theme-color);
}

.checkboxLabel {
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}

.conditions {
  color: rgb(var(--main-bg));
  cursor: pointer;
  text-decoration: none;
}

.button {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--main-theme-color);
  border: 0;
  cursor: pointer;
}

.button span {
  color: var(--white);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.button.disabled {
  opacity: 0.4;
  cursor: unset;
}

.inputError {
  color: red;
  font-family: var(--main-font);
  font-size: calc(var(--main-font-size) - 2px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.error {
  color: var(--secondary-color);
  text-align: center;
  font-family: var(--main-font);
  font-size: calc(var(--main-font-size) + 1px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.selectFormContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  column-gap: 10px;
}

.innerSelectContainers {
  flex: 1;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 10px;
  pointer-events: none;
  color: gray;
}

select {
  appearance: none; /* Remove default appearance */
}

option:disabled {
  color: #747474;
}

.selectInput {
  border-radius: 3px;
  border: 0.5px solid var(--secondary-color);
  padding: 15px 17px;
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.selectInput:focus {
  outline: 1px solid var(--secondary-color);
}

.selectContainer {
  width: 100%;
  position: relative;
  display: inline-block;
}
