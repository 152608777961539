.backgroundContainer {
  position: relative;
  height: 58px;
  background: var(--main-theme-color);
  border-radius: 5px;
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.bgMaskOne {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
  z-index: 15;
}

.bgMaskTwo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
  z-index: 15;
}

.message {
  color: var(--white);
  text-align: center;
  font-family: var(--main-font);
  font-size: calc(var(--main-font-size) - 2px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
  z-index: 18;
}

.message p {
  margin-block: 5px;
}

.mail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 18;
}

.mail img {
  width: 17px;
  height: 17px;
  object-fit: contain;
  margin-right: 5px;
}

.mail a {
  color: var(--white);
  font-family: var(--main-font);
  font-size: calc(var(--main-font-size) - 2px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}
