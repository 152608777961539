.faqContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  animation-name: fade-in;
  animation-duration: 0.3s;
}

.button {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--main-theme-color);
  border: 0;
  margin-top: 10px;
  cursor: pointer;
}

.button span {
  color: var(--white);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  white-space: nowrap;
  margin-block: 20px;
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tabs::-webkit-scrollbar-thumb {
  display: none;
}

.tabItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px 16px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.tabItem.active {
  background-color: rgba(var(--secondary-rgb), 0.4);
  border-radius: 20px;
}

.tabItem span {
  color: var(--black);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.accordionContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  animation-name: fade-in;
  animation-duration: 0.3s;
  margin-top: 20px;
}

.accordionContainer::-webkit-scrollbar {
  display: none;
}

.accordionContainer::-webkit-scrollbar-thumb {
  display: none;
}

.contentLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
