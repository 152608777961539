.winnersContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-inline: 1px;
}

.contentContainer h3 {
  color: var(--black);
  font-family: var(--main-font);
  font-size: calc(var(--main-font-size) + 4px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 20px;
}

.contentContainer::-webkit-scrollbar {
  display: none;
}

.contentContainer::-webkit-scrollbar-thumb {
  display: none;
}

.button {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--main-theme-color);
  border: 0;
  margin-top: 10px;
  cursor: pointer;
}

.button span {
  color: var(--white);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dateInput {
  flex: 1;
  padding: 15px 20px;
  border-radius: 3px;
  border: 0.5px solid var(--secondary-color);
  color: var(--black);
  font-family: var(--hindi-font-family);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dateInput:focus-visible {
  outline: 1px solid var(--secondary-color);
}

.dateContainer {
  display: flex;
  position: relative;
  margin-top: 5px;
  margin-bottom: 50px;
}

.calender {
  position: absolute;
  top: 12px;
  right: 15px;
  width: 22px;
  height: 22px;
  object-fit: contain;
  pointer-events: none;
}

.winnersInnerContainer {
  display: flex;
  flex-direction: column;
}

.winnerItem {
  height: 50px;
  border-radius: 5px;
  background: var(--secondary-color);
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 10px 30px;
}

.winnersMask {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 15;
}

.celMaskOne {
  position: absolute;
  top: 0;
  left: 0;
  width: 89px;
  height: 74px;
  z-index: 15;
}

.celMaskTwo {
  position: absolute;
  top: 0;
  right: 0;
  width: 89px;
  height: 74px;
  flex-shrink: 0;
  z-index: 15;
}

.winnerInfo {
  display: flex;
  flex-direction: column;
  z-index: 18;
}

.winnerTitle {
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.winnerCity {
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
}

.slider {
  display: flex;
  flex-direction: column;
  margin-block: 20px;
}

.sliderItem {
  display: flex;
  flex-direction: column;
  padding-inline: 5px;
}

.sliderImg {
  width: 100%;
  height: 160px;
  object-fit: cover;
  margin-bottom: 14px;
  border-radius: 5px;
}

.sliderInfo {
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 5px;
}

.contentLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
}

.errorIcon {
  background-color: var(--secondary-color);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.errorIcon img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.errorMessage {
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
