.innerContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  animation-name: fade-in;
  animation-duration: 0.3s;
}

.contentLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
}

.bannerImg {
  width: 100%;
  height: auto;
  object-fit: fill;
  border-radius: 10px;
  margin-bottom: 40px;
}

.button {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--main-theme-color);
  border: 0;
  margin-top: 10px;
  cursor: pointer;
}

.button span {
  color: var(--white);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.attemptButton {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: rgba(var(--secondary-rgb), 0.4);
  border: 2px solid var(--secondary-color);
  margin-top: 10px;
  cursor: pointer;
}

.attemptButton span {
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headLine {
  color: var(--black);
  font-family: var(--main-font);
  font-size: calc(var(--main-font-size) + 4px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}

.floatingContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.floatingContainer::-webkit-scrollbar {
  display: none;
}

.floatingContainer::-webkit-scrollbar-thumb {
  display: none;
}

.outerMap {
  width: calc(50% - 5px);
  box-sizing: border-box;
}

.outerMap.fullWidth {
  width: 100%;
}

.city {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.city img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.city span {
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.divider {
  width: 100%;
  height: 1px;
  border-top: 1px solid var(--secondary-color);
  margin-block: 15px;
}

.cityContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.twoPerRow {
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
}

.errorMessage {
  color: var(--black);
  font-family: var(--main-font);
  font-size: calc(var(--main-font-size) + 2px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}

@media only screen and (max-width: 300px) {
  .bannerImg {
    height: auto;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
