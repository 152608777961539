.container {
  position: relative;
  z-index: 2;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-family: var(--main-font);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 35px;
}

.header.lessMargin {
  margin-bottom: 20px;
}

.mainLogo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  -webkit-tap-highlight-color: transparent;
}

.mainLogoImg {
  width: 160px;
  height: 40px;
  cursor: pointer;
  object-fit: contain;
}

.mainLogoImg.circular {
  width: 60px;
  height: 60px;
}

.mainLogo.backButton {
  flex: 1;
  padding-left: 0;
}

.menuIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.content {
  flex: 1;
  background-color: white;
  color: black;
  padding: 15px;
  border-radius: 10px;
  overflow: auto;
  display: flex;
  animation-name: fade-in;
  animation-duration: 0.5s;
}

.contentLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
}

.errorMessage {
  color: var(--black);
  font-family: var(--main-font);
  font-size: var(--main-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
